<template>
  <div class="content">
    <div class="tbl_wrap">
      <div class="tbl_head float_area">
        <div class="float-left">
          <div class="tbl_result">
            <h5>
              코드 목록<span class="tit_desc"
                >검색결과 (총 {{ state.totalItems }}건)</span
              >
            </h5>
          </div>
        </div>
        <div class="float-right align-items-center">
          <div class="tbl_btnset">
            <form
              class="search-group"
              @submit.prevent="
                getItems({
                  page: 1,
                  size: 15,
                })
              "
            >
              <input
                type="text"
                class="form-control"
                v-model="state.searchKeyword"
                placeholder="상위코드 입력"
              />
            </form>
            <!-- <router-link :to="{ name: 'CreateCodeItem' }"> -->
            <button
              type="button"
              class="btn btn-primary"
              @click="openModal('create')"
            >
              <i class="mr-5px"
                ><font-awesome-icon
                  class="gnb_icon"
                  type="fas"
                  icon="fa-plus"
                ></font-awesome-icon> </i
              >등록
            </button>
            <!-- </router-link> -->
            <button
              type="button"
              class="btn btn-secondary"
              :disabled="!state.checkedItems.length"
              @click="deleteItems"
            >
              선택 삭제
            </button>
          </div>
        </div>
      </div>
      <BaseTable
        :useNo="true"
        :fields="state.fields"
        :items="state.items"
        :is-edit="false"
        :isCheckbox="true"
        :loading="state.contentLoading"
        :totalItems="state.totalItems"
        :pageSize="state.pageSize"
        :currentPage="state.currentPage"
        @onChecked="onChecked"
        @onTableClick="onTableClick"
      >
      </BaseTable>
      <nav class="mt-20px">
        <BasePagination
          v-if="state.totalPages > 1"
          :total="state.totalItems"
          :loading="state.contentLoading"
          :currentPage="state.currentPage"
          :pageCount="state.totalPages"
          :perPage="state.pageSize"
          @change="
            (page) =>
              getItems({
                page: page,
                size: 15,
              })
          "
        />
      </nav>
      <Modal
        :target="state.target"
        v-if="getters.getModalStatus(state.target)"
        @clickCreateBtn="clickCreateBtn"
        @clickUpdateSaveBtn="clickUpdateSaveBtn"
        @clickDeleteBtn="clickDeleteBtn"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import codeService from "@/services/code.service";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import Modal from "@/components/core/Modal.vue";

// # hooks
const router = useRouter();
const store = useStore();
const getters = computed(() => store.getters);

// # state
const state = reactive({
  target: "code",
  items: [],
  checkedItems: [],
  searchKeyword: null,
  showModal: false,
  contentLoading: true,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 15,
  userId: 0,
  fields: [
    // table thead fields
    {
      key: "code",
      label: "코드",
      useClick: true,
      width: "25%",
    },
    {
      key: "codeName",
      label: "코드명",
      useClick: true,
      width: "25%",
    },
    {
      key: "upperCode",
      label: "상위",
      useClick: true,
      width: "20%",
    },
    {
      key: "createDate",
      label: "작성날짜",
      useClick: true,
      width: "20%",
    },
  ],
});

// # method
// 코드 다건 조회
const getItems = async (params = { page: 1, size: 15 }) => {
  if (state.searchKeyword != null) {
    params["upperCode"] = state.searchKeyword;
  }
  const res = await codeService.findAll(params);
  state.items = res.data.content;
  // baseTable Component에서 체크박스가 items[0].id 기반으로 작동하기때문에 items 객체에 id속성 추가
  state.items.forEach((item) => {
    item.id = item.code;
  });
  state.currentPage = params.page;
  state.totalItems = res.data.totalElements;
  state.totalPages = res.data.totalPages;
  state.contentLoading = false;
};
// 코드 다건 삭제
const deleteItems = async () => {
  const confirmText =
    "선택한 코드의 하위코드도 모두 삭제됩니다.  \n정말 삭제하시겠습니까??";
  if (confirm(confirmText)) {
    await codeService.deleteAll({ codeList: state.checkedItems.join(",") });
    router.go();
  } else {
    return;
  }
};

// 체크
const onChecked = (items) => {
  state.checkedItems = items.map((item) => item.code);
};

// 모달창 생성
const openModal = (type) => {
  store.commit("setModalMode", { target: state.target, mode: type });
  store.commit("setModalStatus", { target: state.target, status: true });
};

// 행 클릭시 조회모달 생성 ===========
const onTableClick = (checkedItem) => {
  const params = checkedItem.item.code;
  codeService.findOne(params).then(
    (response) => {
      if (response.status === 403) {
        if (!alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요")) {
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          store.commit("LOGOUT");
          router.push({
            name: "Login",
          });
        }
      }

      for (var key in response.data) {
        store.commit("setModalItem", {
          target: state.target,
          key: key,
          value: response.data[key],
        });
      }
      openModal("read");
    },
    (error) => {
      console.error(error.data);
    }
  );
};

// 모달에서 등록실행
const clickCreateBtn = () => {
  const params = {};
  for (const key in getters.value.getModalItem(state.target)) {
    params[key] = getters.value.getModalItem(state.target)[key].value;
  }

  codeService.create(params).then((response) => {
    if (response.status === 403) {
      alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
      // TODO 데이터 초기화
      store.commit("setModalStatus", { target: state.target, status: false });
      store.commit("LOGOUT");
      router.push({
        name: "Login",
      });
    } else if (response.status === 200) {
      alert("코드등록이 완료되었습니다.");
      // TODO 데이터 초기화
      store.commit("setModalMode", { target: state.target, mode: "" });
      store.commit("setModalStatus", { target: state.target, status: false });
      router.go();
    } else {
      alert("에러발생");
    }
  });
};

// 모달에서 수정실행
const clickUpdateSaveBtn = () => {
  const params = {};
  for (const key in getters.value.getModalItem(state.target)) {
    params[key] = getters.value.getModalItem(state.target)[key].value;
  }
  codeService.update(params.code, params).then((response) => {
    if (response.status === 403) {
      alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
      // TODO 데이터 초기화
      store.commit("setModalStatus", { target: state.target, status: false });
      store.commit("LOGOUT");
      router.push({
        name: "Login",
      });
    } else if (response.status === 200) {
      alert("코드수정이 완료되었습니다.");
      // TODO 데이터 초기화
      store.commit("setModalMode", { target: state.target, mode: "read" });
    } else {
      alert("에러발생");
    }
  });
};

// 모달에서 수정실행
const clickDeleteBtn = () => {
  const confirmText =
    "선택한 코드의 하위코드도 모두 삭제됩니다.  \n정말 삭제하시겠습니까??";
  if (confirm(confirmText)) {
    codeService
      .deleteAll({
        codeList: getters.value.getModalItem(state.target)["code"].value,
      })
      .then((response) => {
        if (response.status === 403) {
          alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
          // TODO 데이터 초기화
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          store.commit("LOGOUT");
          router.push({
            name: "Login",
          });
        } else if (response.status === 200) {
          alert("코드삭제가 완료되었습니다.");
          // TODO 데이터 초기화
          store.commit("setModalMode", { target: state.target, mode: "" });
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          router.go();
        } else {
          alert("에러발생");
        }
      });
  }
};

// # lifecycle
onMounted(() => {
  getItems();
});
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .post {
    display: flex;
    gap: 5px;
  }
  a {
    color: blue;
  }
}
.search-group {
  display: flex;
  gap: 10px;
}
.filter-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn-group {
  display: flex;
  gap: 10px;
  * {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ddda;
    border-radius: 5px;
    color: #666;
    &:hover {
      background-color: #ddd;
    }
  }
}
.tbl_btnset {
  font-size: unset;
  display: flex;
  .search-group {
    button {
      flex-basis: 50px;
    }
  }
}
</style>
